import { Flex, Icon } from '@chakra-ui/react'
import React from 'react'
import { FiFile } from 'react-icons/fi'
import { useDropzone } from 'react-dropzone'
import { DOPPEL_CYBER_BLUE } from '../../utils/style'

export default function FileDropzone({ setSelectedFile, acceptedFileTypes }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0])
    },
    multiple: false,
    onDragLeave: () => {},
    onDragOver: () => {},
    onDragEnter: () => {},
    accept: acceptedFileTypes,
  })
  return (
    <Flex
      alignItems="center"
      backgroundColor={'#FFFFFF'}
      borderColor={isDragActive ? DOPPEL_CYBER_BLUE : '#eeeeee'}
      borderRadius="lg"
      borderStyle="dashed"
      borderWidth="4px"
      color={'#bdbdbd'}
      justifyContent="center"
      padding="20px"
      transition="border .24s ease-in-out"
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <>
          <Icon as={FiFile} marginRight="4px" />

          <p>Drag 'n' drop some files here, or click to select files</p>
        </>
      )}
    </Flex>
  )
}
