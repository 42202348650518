import { Button } from '@chakra-ui/react'
import {
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
  DOPPEL_BUTTON_GREY,
} from '../../utils/style'
;``
const DoppelPrimaryButton = ({ children, isInternal = false, ...props }) => {
  return (
    <Button
      as={Button}
      bgColor={isInternal ? DOPPEL_INTERNAL_PURPLE : DOPPEL_BUTTON_GREY}
      fontSize="14"
      minWidth="80px"
      textColor={DOPPEL_WHITE}
      {...props}
    >
      {children}
    </Button>
  )
}

export default DoppelPrimaryButton
