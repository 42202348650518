import React from 'react'
import { InputGroup, Input, InputRightElement } from '@chakra-ui/input'
import { Text, VStack } from '@chakra-ui/react'
import { Button } from '@chakra-ui/button'
import { copyTextToClipboard } from '../../utils'
import { DOPPEL_CYBER_BLUE } from '../../utils/style'
import { useForm } from 'react-hook-form'

type TextInputFieldProps = {
  dbValue: string
  label?: string
  isDisabled?: boolean
  isCopyable?: boolean
}

export default function TextInputField({
  dbValue,
  label,
  isDisabled = true,
  isCopyable = true,
  ...props
}: TextInputFieldProps) {
  const { register: formRegister, watch: formWatch } = useForm()
  const currentValue = formWatch(label ?? '') === '' ? null : formWatch(label ?? '')
  return (
    <VStack align={'left'} {...props}>
      {label && (
        <Text fontSize="12px">
          <b>{label}</b>
        </Text>
      )}

      <InputGroup size="md">
        <Input
          alignItems="center"
          borderColor={
            currentValue == undefined || currentValue == dbValue
              ? '#DEDEDE'
              : DOPPEL_CYBER_BLUE
          }
          borderRadius="lg"
          borderWidth="2px"
          defaultValue={dbValue}
          fontSize="14px"
          isDisabled={isDisabled}
          justifyContent="space-between"
          padding="8px"
          placeholder={'null'}
          width="100%"
          {...(label ? formRegister(label) : {})}
        />

        {isCopyable && (
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" onClick={() => copyTextToClipboard(dbValue)} size="sm">
              Copy
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </VStack>
  )
}
