import { Flex, Tooltip, Box, FormLabel } from '@chakra-ui/react'
import { MdOutlineInfo } from 'react-icons/md'

type FormLabelProps = {
  formLabel: string
  helperText?: string
  fontSize?: string
}

const DoppelFormLabel = (labelProps: FormLabelProps) => {
  const { formLabel, helperText, fontSize } = labelProps

  return (
    <Flex alignItems="center">
      <FormLabel fontSize={fontSize || 'md'} marginBottom={0}>
        {formLabel}
      </FormLabel>

      {helperText && (
        <Box marginLeft={-2}>
          <Tooltip aria-label="info" label={helperText}>
            <span>
              <MdOutlineInfo />
            </span>
          </Tooltip>
        </Box>
      )}
    </Flex>
  )
}

export default DoppelFormLabel
